import { GetStaticProps } from 'next'
import { getLayout } from '@utils/getLayout'
import { getWithDefaultProps } from '@utils/getWithDefaultProps'
import { StatusContent } from 'ui'
import { ExtendedNextPage } from './types'
import { REVALIDATE } from 'common'

const NotFoundPage: ExtendedNextPage = () => {
	return (
		<StatusContent
			title={`ERROR 404\nPage not found.`}
			description="Sorry, we couldn't find the page you were looking for. It could be an incorrect link or a typo in the URL."
			status="error"
		/>
	)
}

export const getStaticProps = getWithDefaultProps<GetStaticProps>(() => {
	return {
		props: {},
		revalidate: REVALIDATE.LONG,
	}
}, false)

NotFoundPage.getLayout = getLayout

export default NotFoundPage
