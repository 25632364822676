import { ComponentPropsWithoutRef, ReactNode } from 'react'
import clsx from 'clsx'
import { OptimizedImage } from '../../atoms'

interface StatusContentProps extends ComponentPropsWithoutRef<'div'> {
	title: string
	description?: ReactNode | string
	buttons?: ReactNode
	status?: 'success' | 'error'
}

const SUCCESS_IMAGE = '/images/circle-check.png'
const ERROR_IMAGE = '/images/error.png'

export const StatusContent = ({
	title,
	description,
	buttons,
	className,
	status = 'success',
}: StatusContentProps) => {
	const isSuccess = status === 'success'

	return (
		<div
			className={clsx(
				'relative mx-auto max-w-[680px] px-4 pt-14 text-center md:pb-10 lg:pb-6',
				className
			)}
		>
			<OptimizedImage
				src={isSuccess ? SUCCESS_IMAGE : ERROR_IMAGE}
				alt={isSuccess ? 'Success' : 'Error'}
				className="m-auto"
				width={272}
				height={272}
				priority
			/>
			<h1 className="text-gray-90 mb-6 whitespace-pre-line">{title}</h1>
			{description && <p className="mb-6">{description}</p>}
			{buttons && (
				<div className="xs:flex-col flex justify-center gap-2">{buttons}</div>
			)}
		</div>
	)
}
